[current-path="/login"] {
    display: none !important;
}

.avatarLogoCol {
    display: flex;
    justify-content: center;
}

ion-avatar {
    width: 100px !important;
    height: 100px;
}

#login-container ion-content{
    --background: #2c3e50;
}