[current-path="/main"] {
    display: flex !important;
}

ion-avatar{
    width: unset;
    height: unset;
}


ion-avatar .logoImage{
    cursor: pointer;
}

#grid-main ion-card {
    border-left: 5px solid #222428;
}